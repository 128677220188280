import { AuthConfigBuilder } from './config/auth-config.builder';
import { Injectable, inject } from '@angular/core';
import { AppConfig, ENV } from '@core/config/app.config';
import { OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, from, map, of, switchMap, take } from 'rxjs';

export enum AuthState {
  Uninitialized = 'Uninitialized',
  Initialized = 'Initialized',
  Unauthenticated = 'Unauthenticated',
  Authenticated = 'Authenticated',
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  private config: AppConfig = inject(ENV);
  private oauth: OAuthService = inject(OAuthService);

  private authState = new BehaviorSubject<AuthState>(AuthState.Uninitialized);
  public state$ = this.authState.asObservable();

  constructor() {
    if(!this.config.auth) {
      throw new Error('AuthConfig is missing in the environment config');
    }
    
    this.oauth.configure(
      AuthConfigBuilder.from(this.config.auth).build()
    );
  }

  public isAuthenticated$(): Observable<boolean> {
    return this.state$.pipe(
      map(currentState => currentState == AuthState.Authenticated),
    )
  }

  public fetchToken$(): Observable<AuthState> {
    return this.init$().pipe(
      switchMap(state => 
        from(this.oauth.tryLoginCodeFlow()).pipe(
          map(_ => this.updateAuthState(state))
        )
      ),
    )
  }

  public verify$(): Observable<AuthState> {
    return this.init$().pipe(
      map(state => this.updateAuthState(state))
    )
  }

  private init$(): Observable<AuthState> {
    return this.state$.pipe(
      take(1),
      switchMap(state =>
        (state == AuthState.Uninitialized)
          ? this.initCodeFlow$()
          : of(state)
      )
    )
  }

  private initCodeFlow$(): Observable<AuthState> {
    return from(this.oauth.loadDiscoveryDocument()).pipe(
      map(() => {
        this.authState.next(AuthState.Initialized);
        return AuthState.Initialized;
      })
    )
  }

  private updateAuthState(currentState: AuthState): AuthState {
    const state = this.isSignedIn()
      ? AuthState.Authenticated
      : AuthState.Unauthenticated;

    if(state !== currentState) this.authState.next(state);
    return state;
  }

  public signIn(): void {
    this.oauth.initCodeFlow();
  }

  public refreshToken(): Observable<TokenResponse> {
    return from(this.oauth.refreshToken());
  }


  public isSignedIn(): boolean {
    return this.oauth.hasValidAccessToken() && this.oauth.hasValidIdToken();
  }

  public signOut(options = {noRedirectToLogoutUrl: false}): void {
    this.authState.next(AuthState.Unauthenticated);
    this.oauth.logOut(options);
  }

  public get header(): string {
    return this.oauth.authorizationHeader();
  }
  public get expiryDate(): number {
    return this.oauth.getAccessTokenExpiration();
  }
  public get expired(): boolean {
    return this.expiryDate <= new Date().getTime();
  }
}
