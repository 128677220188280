import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Injector } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { createCustomElement } from '@angular/elements';

import { FeaturesModule } from 'src/app/features/features.module';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '@shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [CoreModule, FeaturesModule, AppRoutingModule, BrowserModule, SharedModule],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(private injector: Injector) {}

  ngDoBootstrap() {
    const appElement = createCustomElement(AppComponent, { injector: this.injector });
    customElements.define('app-root', appElement); // Geef je webcomponent een naam
  }
}
