import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments/environment';
import * as moment from 'moment';
import { AppModule } from './app/app.module';
import { AppEnvironment } from './app/app.environment';
import { AppConfig, ENV } from '@core/config/app.config';

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
  
// Config is the first thing that needs to be fetched since we don't store it in environment files.
fetch((environment as AppEnvironment).config[window.origin] ?? (environment as AppEnvironment).config?.fallback)
  .then(resp => resp.json())
  .then((appConf: AppConfig) => {
    // Sets global lang for moment
    moment.locale('nl');

    if (environment.production) {
      enableProdMode();
    }

    return platformBrowserDynamic([{ provide: ENV, useValue: appConf }])
      .bootstrapModule(AppModule)
  })
  .catch(err => {
    console.error('Failed to load app!');
    if(!environment.production) console.error(err);
  });
