import { IdentityRestService } from '@core/http/identity-rest.service';
import { Component, ErrorHandler, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription, take } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AccountStoreService } from '@core/store/account-store.service';
import { AppConfig, ENV } from '@core/config/app.config';
import { AuthService, AuthState } from '@auth/auth.service';
import * as moment from 'moment';
import { ErrorService } from '@core/error/error.service';
/**
 * Exchanges Parro token for ParnasSys accountportaal token. 
 */
@Component({
  selector: 'par-assertion-callback',
  template: '',
  styles: ['']
})
export class AssertionCallbackComponent implements OnInit, OnDestroy {
  private config: AppConfig = inject(ENV);

  private verifyAuthStateSub: Subscription = new Subscription();

  constructor(
    private readonly authService: AuthService,
    private router: Router,
    private httpClient: HttpClient,
    private error: ErrorHandler
  ) {}

  ngOnInit(): void {
    if (this.authService.isSignedIn()) {
      this.authService.signOut({noRedirectToLogoutUrl: true});
    }

    const params = new URLSearchParams(location.search);
    const assertion = params.get('assertion') as string;

    if (!assertion) {
      this.router.navigate(['/']);
      return;
    }

    const config = new HttpParams({
      fromObject: {
        grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
        assertion: assertion,
        client_id: this.config.auth.clientId
      }
    });

    this.httpClient
      .post(this.config.auth.IDP_url + '/token', config, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .subscribe({
        next: (result: any) => {
          sessionStorage.setItem('access_token', result.access_token);
          sessionStorage.setItem('refresh_token', result.refresh_token);
          sessionStorage.setItem('scope', result.scope);
          sessionStorage.setItem('id_token', result.id_token);
          sessionStorage.setItem('token_type', result.token_type);
          sessionStorage.setItem('expires_at', moment(Date.now()).add(result.expires_in, 'seconds').toDate().getTime().toString());

          this.verifyAuthStateSub = this.authService
            .verify$()
            .subscribe(state => {
              if (state !== AuthState.Authenticated) {
                this.error.handleError(new Error('Token assertion failed, current state: ' + state));
                this.router.navigate(['/', 'login']);
                return;
              }
              this.router.navigate(['/']);
            })
        },
        error: error => {
          this.error.handleError(new Error(error));
          this.router.navigate(['/', 'login']);
        }
      });
  }

  ngOnDestroy(): void {
    this.verifyAuthStateSub.unsubscribe();
  }
}
