<div mat-dialog-title>
    <h3>{{ 'header' | prefix: this | translate }}</h3>
    <button
      ft-button
      [mat-dialog-close]="true"
      appearance="ghost-monochrome"
      icon="cross">
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <p>{{ 'description' | prefix: this | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    ft-button
    (click)="onRemoveTwofactor()"
    appearance="secondary">
    {{ 'btn_confirm' | prefix: this | translate }}
  </button>
  <button
    ft-button
    [mat-dialog-close]="true"
    appearance="primary">
      {{ 'btn_decline' | prefix: this | translate }}
  </button>
</mat-dialog-actions>
