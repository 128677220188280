// Geeft aan de hand van ParnasSys controle een percentage van 'sterkte' terug en een boolean die aangeeft of het wachtwoord aan de lengte eisen voldoet.
export class PasswordHelper {
  static calcProgression(messages: string[], pwd: string, tooShortWeight: number = 50, normalErrorWeight: number = 10): [number, boolean] {

  let tooShort = false;

    // Check if alerts contain minimum characters alert
    const percentage = messages.reduce((remainingPercentage, message) => {
      let errorPercentage = normalErrorWeight; // amount or perc to be removed

      message.match(/minimaal (\d+) karakters/i)?.forEach(match => {
        const minimalRequiredCharacters = isNaN(+match) ? pwd.length : +match;

        // 'perc of characters missing' x 'weight of pwd-too-short message'
        const amountOfCharsMissing = 1.0 - (pwd.length / minimalRequiredCharacters);
        errorPercentage = Math.round( amountOfCharsMissing * tooShortWeight);
        tooShort = true;
      });

      return remainingPercentage - errorPercentage;
    }, 100);

    return [Math.max(percentage, 0), tooShort];
  }
}
